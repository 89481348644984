import videojs from "video.js";
import 'video.js/dist/video-js.css';

Array.from(document.querySelectorAll('section.hero .video-js')).map((video) =>{
    const player = videojs(video, {
        children: [
            'bigPlayButton'
        ]
    })
})

