import SimpleParallax from "simple-parallax-js";
import Swiper from "swiper";
import 'swiper/swiper-bundle.css'


var imagesLeft = document.querySelectorAll('.dual-image-cta__image--left');
var imagesRight = document.querySelectorAll('.dual-image-cta__image--right');

new SimpleParallax(imagesLeft, {
    scale: 1.1,
    orientation: 'down'
});
new SimpleParallax(imagesRight, {
    scale: 1.2,
    orientation: 'up'
});

Array.from(document.querySelectorAll('.dual-action-cta__image-gallery.swiper')).map(gallery => {
    const swiper = new Swiper(gallery)
})