import '../src/global.scss';
import '../src/footer.scss';
import '../template-parts/call-to-action/call-to-action.scss'
import '../template-parts/dual-image-call-to-action/dual-image-call-to-action.scss'
import '../template-parts/dual-image-call-to-action/dual-image-call-to-action'
import '../template-parts/hero/hero.scss'
import '../template-parts/hero/hero'
import '../template-parts/image/image.scss'
import '../template-parts/navbar/navbar.scss'
import '../template-parts/page-title/page-title.scss'
import '../template-parts/text/text.scss'
import "../template-parts/three-column-text/three-column-text.scss"


const handleIntersection = (entries) => {
    entries.forEach(entry => {
        const section = entry.target;
        if (entry.isIntersecting) {
            section.setAttribute('data-visible', 'true');
            return;
        }
    });
};

// Create an intersection observer with the callback function
const observer = new IntersectionObserver(handleIntersection, {
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.1 // Trigger when 10% of the element is visible
});

// Get all the section elements and observe them
const sections = document.querySelectorAll('main.site-main > section');
sections.forEach(section => {
    observer.observe(section);
});


